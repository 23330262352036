::-webkit-scrollbar {
    width: 3px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ddd; 
    border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #E62D26; 
}