/* =================================================================================
    Template Name : Nationtech  Communication Admin
    Author       : Joyonto Kumar Roy
    Author URI : https://hamaronline.com/
    Version      : 1.0.0
====================================================================================*/
@media only screen and (min-width: 1800px) {
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .login-container {
    .login-banner {
      .login-introduction {
        top: 50%;
      }
    }
    .admin-login-wrapper {
      padding: 20px 36px;
      h2 {
        margin-top: 16px;
      }
      .admin-login-form {
        padding: 0 27px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .login-container {
    .admin-login-wrapper {
      padding: 18px 25px;
      h2 {
        margin-top: 12px;
      }
      .admin-login-form {
        padding: 0 27px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .login-container {
    .login-introduction {
      padding: 10px;
      img {
        margin-top: 30px;
      }
      h3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
