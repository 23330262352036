@import "./variables";
html,
body {
  color: $primary-text-color;
  font-size: $primary-font-size;
  line-height: $primary-line-height;
  font-weight: $primary-font-weight;
  font-family: $primary-font;
}

img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

p {
  margin: 0;
  color: $primary-text-color;
  font-size: $primary-font-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
  padding: 0 0;
  font-family: $primary-font;
}

h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  font-family: $primary-font;
}

h2 {
  font-size: 45px;
  line-height: 58px;
  font-weight: 700;
  font-family: $primary-font;
}

h3 {
  font-size: 25px;
  line-height: 36px;
  font-weight: 700;
  font-family: $primary-font;
}

h4 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  font-family: $primary-font;
}

h5 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: $primary-font;
}

h6 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  font-family: $primary-font;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  outline: none !important;
  font-family: $primary-font;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  font-family: $primary-font;
}

.hidden {
  overflow: hidden;
}

i {
  font-family: "fontawesome";
  font-style: inherit;
}

.form-control:focus {
  background-color: $white-bg;
  outline: 0;
  box-shadow: none;
}
input:focus {
  outline: 0;
  box-shadow: none;
  background: none;
}
textarea:focus {
  border: 1px solid transparent !important;
  box-shadow: none;
  border-radius: 0 !important;
  outline-color: #345995 !important;
}
::-moz-selection {
  text-shadow: none;
  color: $white-bg;
}

::selection {
  text-shadow: none;
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}
input:disabled {
  background: #cccccc70 !important;
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

input[type="submit"] {
  cursor: pointer;
  background: #4466f2;
}
input[type="submit"]:focus {
  background: #4466f2;
  outline: none;
}
.no-padding {
  padding: 0;
}
.submit-form {
  background: #4466f2;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-width: 300px;
  min-height: 42px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
}
.mt-27 {
  margin-top: 27px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-25 {
  margin-bottom: 25px;
}
.white-bg {
  background-color: #fff;
}
.section-padding-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.section-padding {
  padding: 30px 0;
}
.section-padding-bottom {
  padding-bottom: 15px;
}
.success-color {
  color: #34a852;
}
.failed-color {
  color: #d1011d;
}

.row--aligned [class*="col-"] {
  display: flex;
}
button[disabled] {
  cursor: no-drop;
  background: #e6d0d03b;
}

input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
input:-webkit-autofill {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-l {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.d-flex-r {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.d-flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex-dir-col {
  flex-direction: column;
}

.d-flex-wrap {
  flex-wrap: wrap;
}

.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.ml-15 {
  margin-left: 15px;
}

.height-32 {
  height: 32px;
}
.height-37 {
  height: 37px;
}
.width-full {
  width: 100%;
}
.red-txt {
  color: $theme-color;
}
.txt-center {
  text-align: center;
}
.text-error {
  color: rgb(223, 4, 4);
}

.p-5 {
  padding: 5px;
}
.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.pt-5 {
  padding-top: 5px;
}
.pb-5 {
  padding-bottom: 5px;
}

.p-10 {
  padding: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}

.p-15 {
  padding: 15px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.pt-15 {
  padding-top: 15px;
}
.pb-15 {
  padding-bottom: 15px;
}

.m-5 {
  margin: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}

.m-10 {
  margin: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}

.m-15 {
  margin: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}

.m-auto {
  margin: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}

.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}

.pointer {
  cursor: pointer;
}
