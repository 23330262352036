/*=================================
dashboard design
==================================*/
.dashboard-box-area {
  margin-top: 15px;
  .dashboard-box {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    transition: all 0.1s linear;
    position: relative;
    top: 0;
    padding: 40px 20px;
    &:hover {
      top: -5px;
    }
    &.order-box {
      background-color: #daf5ff;
    }
    &.amount-box {
      background-color: #e7f1fe;
    }
    &.today-amount-box {
      background-color: #e6f4ea;
    }
    &.pending-order-box {
      background-color: #fee7fd;
    }
    &.active-shop-box {
      background-color: #e6f4ea;
    }
    &.cancel-order-box {
      background-color: #feeeed;
    }
    &.total-user-box {
      background-color: #faecc8;
    }

    .box-heading {
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 49px;
      margin: 0;
      &.order-box-heading {
        color: #44b4db;
      }
      &.amout-box-heading {
        color: #4384f3;
      }
      &.pending-box-heading {
        color: #6c00c1;
      }
      &.active-shop-heading {
        color: #99c109;
      }
      &.cancel-order-heading {
        color: #d1011d;
      }
      &.total-user-heading {
        color: #fab400;
      }
    }
    p {
      font-weight: 600;
      font-size: 14px;
      color: #3a3a3a;
    }
    .filter-box {
      box-sizing: border-box;
      border-radius: 100px;
      border: none;
      padding: 7px 18px;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      background: transparent;
      cursor: pointer;
      &.order-box-filter {
        border: 1px solid #44b4db;
        color: #44b4db;
      }
      &.amount-box-filter {
        border: 1px solid #4384f3;
        color: #4384f3;
      }
      &.pending-box-filter {
        border: 1px solid #6c00c1;
        color: #6c00c1;
      }
      &.active-shop-filter {
        border: 1px solid #99c109;
        color: #99c109;
      }
      &.cancel-order-filter {
        border: 1px solid #d31726;
        color: #d1011d;
      }
      &.total-user-filter {
        border: 1px solid #fab400;
        color: #fab400;
      }
    }
  }
  .calender-area {
    .react-calendar {
      margin-top: 30px;
      width: 100%;
      max-width: 100%;
      background: $white-bg;
      border: none;
    }
  }
}
