.ant-layout-sider-children {
  .ant-menu-item {
    &.active {
      background-color: #006edc !important;
    }
    &.ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
}
.ant-menu {
  &.ant-menu-dark {
    .ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
}
.ant-menu-dark {
  &.ant-menu-dark:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
}
.ant-menu-dark {
  &.ant-menu-dark:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
}
