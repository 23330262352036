.single-content {
  margin-bottom: 20px;
  .heading {
    span {
      display: inline-block;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 7px;
    }
  }
}
