.list-view-wrapper {
  padding: 20px 15px 15px 15px;
  background: #f0f2f5;
  box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  margin-bottom: 15px;
  .single-list-view {
    display: flex;
    align-items: center;
    .logo {
      flex-shrink: 0;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }
    }
    .info {
      margin-left: 12px;
      .name {
        font-size: 15px;
        text-transform: capitalize;
        font-weight: 400;
        margin-bottom: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 22px;
      }
    }
  }
  .list-action {
    .right-action {
      a {
      }
      button {
        border: none;
        margin-left: 12px;
      }
    }
  }
}
