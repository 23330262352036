#components-layout-demo-side {
  .logo {
    max-width: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
    margin-bottom: 12px;
  }
}
.ant-layout-sider-children {
  .logo {
    border-bottom: 1px solid #ffffff38;
    padding-bottom: 15px;
    width: 140px;
    margin: auto;
    margin-bottom: 20px;
  }
}

.site-layout {
  .site-layout-background {
    background: #fff;
  }
}
.ant-layout-sider-collapsed {
  .logo {
    width: 60px;
  }
}
