$primary-color: #323232;
$primary-bg-color: #e62d26;
$theme-color: #e62d26;
$primary-font-size: 16px;
$primary-font-weight: 400;
$primary-font: "Uni Neue", sans-serif;
$primary-line-height: 25px;
$primary-text-color: #323232;
$primary-info-color: #2192d9;
$heading-color: #777171;
$gray-bg: #f0f2f5;
// input field
$input-field-color: #939393;
$white-bg: #fff;
$input-border-radius: 2px;
$input-field-height: 37px;
$input-field-border: 1px solid #c8c8c8;
// font-weight
$semibold: 500;
$bold: 700;
$normal: 400;
$thin: 300;
